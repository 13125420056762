// Theme
.header-desktop2 {
    left: 0;
    position: fixed;
}

.header-desktop2 .logo {
    background-color: #365cad;
    height: 100%;
}

.header-desktop2 .companie-name p {
    color: whitesmoke;
    i {
        color: whitesmoke;
    }
}
.tecnic-assistant{
    background-color: #1D3B53;
    padding:1rem 1rem 1rem 1rem;
    border-radius: 2rem;
    border-color: #218ae1;
    border-style: solid;
    border-width: 1px;
    color: #FFDC00 !important;
    font-weight:600;
    text-align: center;
    align-self: center;
    transition: transform 0.3s ease;
}
.tecnic-assistant:hover{
    background-color: #172f42;
    border-color: #FFDC00;
    transform: scale(1.1);
    
}
.button-icon-assistant{
    display: none;
    font-size: 24px;
}
@media only screen and (max-width: 800px) {
    .tecnic-assistant{
        padding:1rem 2rem 1rem 2rem;
    }
    .button-icon-assistant {
        display: inline-block; /* Mostrar el ícono en pantallas pequeñas */
       
      }
    .button-text-assisant{
        display: none;
    }
}
.page-wrapper {
    padding-bottom: 0vh;
}

.page-container2 {
    padding-left: 0;
}

.page-content--bge5 {
    height: 100%;
    min-height: 100vh;
}

.main-content {
    padding-top: 30px;
    padding-bottom: 30px;
}

.au-progress__value {
    top: -20px;
}

.au-progress__title {
    margin-bottom: 15px;
    display: list-item;
}

// Left menu
#leftMenuNavbarCollapse {
    padding-left: 0;
    padding-right: 0;
    overflow-y: auto;
    ul li {
        text-align: left;
    }
}

#rightMenuNavbar .navbar-toggler {
    border: none;
    font-size: 24px;
}

.navbar-dark .navbar-toggler {
    border-color: transparent !important;
}

.navbar-toggler {
    display: inline !important;
    opacity: 1;
    color: #fff !important;
}

.navbar-collapse-leftmenu {
    border-right: 1px solid #ddd;
}

.navbar-expand .navbar-collapse-leftmenu {
    position: fixed;
    top: 75px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: calc(100% - 60px);
    z-index: 10000;
}

.navbar-collapse-leftmenu.collapsing {
    left: -75%;
    transition: height 0s ease;
}

.navbar-collapse-leftmenu.show {
    left: 0;
    transition: left 300ms ease-in-out;
}

.navbar-toggler.collapsed~.navbar-collapse-leftmenu {
    transition: left 500ms ease-in-out;
}

// Right menu
#rightMenuNavbar.navbar-expand {
    justify-content: flex-end;
}

#rightMenuNavbarCollapse {
    padding-left: 0;
    padding-right: 0;
    overflow-y: scroll;
    ul li {
        text-align: left;
    }
}

.navbar-collapse-rightmenu {
    border-left: 1px solid #ddd;
}

.navbar-expand .navbar-collapse-rightmenu {
    position: fixed;
    top: 75px;
    right: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: calc(100% - 60px);
    z-index: 10000;
}

.navbar-collapse-rightmenu.collapsing {
    right: -75%;
    transition: height 0s ease;
}

.navbar-collapse-rightmenu.show {
    right: 0;
    transition: right 300ms ease-in-out;
}

.navbar-toggler.collapsed~.navbar-collapse-rightmenu {
    transition: right 500ms ease-in-out;
}

// Colorpicker
.colorexamplediv {
    font-size: 16px;
    color: #ccc0c0;
    margin-top: 10px;
}

.colorexample {
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid #ddd;
}

// DataTables
div.dataTables_processing,
div.dataTables_wrapper div.dataTables_processing,
.dataTables_processing.card,
.dataTables_processing {
    background-image: url(/images/datatable-loading.gif)!important;
    top: -3%!important;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 170px 55%;
    color: #000;
    padding: 1em 0;
    z-index: 9;
    background-color: #f8f9fa!important;
}

div.dataTables_wrapper div.dataTables_paginate {
    margin-top: 2rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0.2em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background-color: #3490dcd6 !important;
    color: #3490dcd6 !important;
}

table.myparticipations img {
    width: 100px;
}

@media (max-width: 991px) {
    .table-responsive label {
        width: 100% !important;
    }
    .table-responsive input {}
}

// Admin settings
#admin-logo-preview-img {
    max-height: 45px;
}

//Mensajes destacados
.message-gray {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background-color: #f5f8fa;
    color: rgba(0, 0, 0, 0.87);
    padding: 15px 30px;
}

.btn.btn-raised {
    box-shadow: none !important;
    background-image: webkit-linear-gradient(top, #f7dfa5, #f0c14b) !important;
    background-image: linear-gradient(to bottom, #f7dfa5, #f0c14b) !important;
    border: 1px solid #babd66;
    color: #2d2d2d !important;
}

.btn.btn-raised:hover {
    background-image: webkit-linear-gradient(top, #ad8422, #f0c14b) !important;
    background-image: linear-gradient(to bottom, #d6b464, #f0c14b) !important;
}

.btn-disabled,
.btn-disabled:hover {
    background-color: #eee!important;
    color: #333!important;
    cursor: default!important;
}

//Button
a.btn-primary,
a.btn-primary:hover,
a.btn-secondary,
a.btn-secondary:hover,
a.btn-danger,
a.btn-danger:hover,
a.btn-success,
a.btn-success:hover,
.btn-secondary a {
    color: #fff;
}

a:focus {
    outline: -webkit-focus-ring-color auto 5px;
    /* outline-offset: -2px; */
}

form .btn {
    margin-top: 30px;
}

.card-footer .btn {
    margin-top: 0px;
}

// Alerts
.alert a {
    color: inherit;
    text-decoration: underline;
}

.login-logo {
    background: #365cad;
    height: 55px;
}

.header-wrap2 .links>a {
    color: white;
    margin: 20px;
    margin-bottom: 5px;
    margin-top: 4px;
    padding: 0px;
}

footer {
    background-color: #365cad;
    padding: 2rem;
    color: white;
    font-size: 14px;
    a {
        color: white;
    }
    a:hover {
        color: rgb(204, 191, 191);
    }
}

.links>a {
    color: white;
    padding: 5px;
    font-size: 14px;
}

//
.backend-tiendaloto .faviconimage i,
.backend-tiendaloto .logoimage i,
.backend-tiendaloto .sliderimage i {
    position: absolute;
    left: calc(100% - 60px);
    top: calc(100% - 135px);
    background: #fff;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    opacity: .8;
}

.backgroundimage i {
    position: absolute;
    left: calc(100% - 60px);
    top: calc(100% - 35px);
    background: #fff;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    opacity: .8;
}

img.logo {
    max-width: 96%;
    max-height: 200px;
}

.faviconimage.morewidth {
    max-width: 130px;
}

img.favicon {
    width: 64px;
    height: 64px;
}

.faviconimage {
    background: #f0f0f0;
    max-width: 100px;
    height: 100px;
    padding: 18px;
    border-radius: 4px;
    margin: auto;
}

.preview-img {
    padding: 5px;
    max-width: 200px;
    display: block;
    img {
        height: 100px;
        display: block;
    }
}

// Forms
label.control-label {
    font-size: 12px;
    line-height: 1.2;
    font-weight: 400;
    margin: 16px 0 0;
}

.label-floating {
    color: rgb(138, 132, 132);
}

.form-group.label-static label.control-label,
.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label {
    padding: 0;
}

.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
    top: -1px;
    padding: 0 10px;
    font-size: 14px;
}

.form-group.is-focused .form-control {
    border-color: #365f79;
    background-image: none !important;
}

.form-control,
.form-group .form-control {
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    background-image: none;
    padding: 0 10px;
    font-size: 14px;
}

.form-group .form-control {
    margin-bottom: 7px;
}

code,
.hightlighted {
    color: #7D40CE;
    background-color: #7D40CE20;
}

.label,
.label.label-default {
    background-color: #9e9e9e;
}

.label.label-success {
    background-color: #83C80E;
}

.label {
    border-radius: 3px;
    padding: .3em .6em;
}

.label {
    display: inline;
    font-size: 75%;
    color: #fff;
}

.label a {
    color: #fff;
    text-decoration: underline;
}

.form-group label.control-label {
    font-size: 13px;
    line-height: 1.2;
    font-weight: 400;
}

.myparticipationjob .big {
    font-size: 44px;
}

li>a.submenu {
    padding-left: 75px !important;
}

// My payments
#form-payments .togglebutton {
    margin-left: -10px;
}

// Breadcrumbs
.au-breadcrumb {
    border-bottom: 1px solid #ddd !important;
    height: unset;
    min-height: 75px !important;
}

.au-breadcrumb .section__content {
    transform: none;
    padding-top: 10px;
    //    margin-top: 100px;
}

.au-breadcrumb-left {
    a {
        color: black;
        font-size: 20px;
        font-weight: 600;
        @media (max-width: 991px) {
            font-size: 17px;
        }
        .breadcrumb-space {
            color: grey;
            margin: 0 2px;
        }
    }
    a:hover {
        text-decoration: underline;
        .breadcrumb-space {
            text-decoration: underline;
            text-decoration-color: #fff;
        }
    }
}

.au-breadcrumb-text-danger {
    color: white !important;
    background: #ff0000c7;
}

.au-breadcrumb-text-danger .small>a.logout-identity {
    color: #3b09d6;
}

@media (max-width: 991px) {
    .au-breadcrumb.m-t-75 {
        margin-top: 75px;
        height: auto;
    }
    .au-breadcrumb .btn {
        width: 100%;
        display: block;
        margin: 2px 0;
    }
    .au-breadcrumb .section__content {
        transform: none;
        padding: 20px 0;
        margin-top: 100px;
    }
    .navbar-expand .navbar-collapse-leftmenu {
        margin-top: 25px;
    }
}

//accept contract
.contract_accepted_needed {
    font-size: 16px;
    color: #f44336;
    cursor: pointer;
    padding: 0 15px;
}

.contract_accepted {
    font-size: 16px;
    font-weight: bold;
    color: #83C80E;
    padding: 0px 15px 10px;
}

// Admin admon
.nav-tabs {
    border-bottom: none;
}

.nav-link {
    padding: 0.5rem 2.5rem;
}

.nav-tabs .nav-link.active,
.nav-pills .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: #6778a2 !important;
    color: #fff !important;
}

.nav-tabs>li>a,
.nav-link {
    color: #6778a2;
}

#pills-tab {
    .nav-link {
        border: 1px solid transparent;
        border-radius: .25rem .25rem 0 0;
    }
    .nav-link:hover {
        border-color: #e9ecef #e9ecef #dee2e6;
    }
}

.navbar__sub-list li,
.setting-menu .account-dropdown__item li a {
    background: #f5f5f5;
    border-color: #ebebeb;
    border-bottom: 1px solid #f2f2f2;
}

.setting-menu .account-dropdown__item li a:hover {
    background: #eee;
}

.setting-menu .account-dropdown__item li.active>a,
.account-dropdown__item.active>a {
    color: #4272d7;
}

//Modals
#modal-error {
    z-index: 1509;
    .modal-dialog {
        box-shadow: 0 27px 24px 0 rgba(0, 0, 0, .2), 0 40px 77px 0 rgba(0, 0, 0, .22);
    }
}



.modal-content {
    box-shadow: 2px 2px 8px 6px #21252973;
}

.modal-content .modal-header {
    background-color: #3490dc !important;
    font-size: 16px;
}

.modal-content .modal-header .modal-title {
    color: #fff;
    font-size: 16px;
    line-height: 2;
}

.modal-footer {
    display: flow-root;
    border-top: none;
}

.close {
    font-size: 2rem;
    color: #fff;
    opacity: 0.8;
}

.close:hover {
    color: #fff;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: 1;
}

//Company
.logoimage {
    text-align: center;
    @media(min-width: 768px) {
        text-align: right;
    }
}

.sliderimage i,
.logoimage i {
    position: absolute;
    left: calc(100% - 65px);
    top: calc(100% - 85px);
    background: #fff;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    opacity: .8;
}

.sliderimage i:hover,
.logoimage i {
    opacity: 1;
}

.logoimage i {
    left: calc(100% - 60px);
    top: calc(100% - 55px);
    padding: 3px 6px;
}

.faviconimage i {
    position: absolute;
    left: calc(100% - 45px);
    top: calc(100% - 35px);
    background: #fff;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    opacity: .8;
}

//Sections Order
li.sortable,
ul.sortable li {
    list-style: none;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px dashed #666;
    margin: 5px 0;
    cursor: move;
    font-size: 14px;
}

li.sortable.default {
    background-color: #d0d0d0;
    cursor: no-drop;
}

ul.sortable li:hover {
    background-color: #e0e0e0;
}

//Boletos Digitales
.shop-config-limit-button {
    padding: 10px 20px;
    border: 1px solid #666;
    margin-bottom: 5px;
    width: 14%;
    cursor: pointer;
    opacity: .3;
}

.shop-config-limit-button:hover {
    cursor: pointer;
    opacity: 0.8;
}

.shop-config-limit-button.selected {
    opacity: 1;
}

.togglebutton label input[type=checkbox]:checked+.toggle {
    background-color: rgba(33, 109, 236, 0.5);
}

input[type=radio],
input[type=checkbox] {
    margin-right: 5px;
    cursor: pointer;
}

.input-group-addon {
    height: calc(1.32em + .75rem + 2px);
    margin-left: -3px;
    font-size: 17px;
    ;
}

.togglebutton {
    vertical-align: middle;
}

.togglebutton label input[type=checkbox],
.togglebutton label input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
}

.togglebutton label .toggle,
.togglebutton label input[type=checkbox][disabled]+.toggle,
.togglebutton .toggle,
.togglebutton input[type=checkbox][disabled]+.toggle {
    content: "";
    display: inline-block;
    width: 30px;
    height: 15px;
    background-color: rgba(80, 80, 80, .7);
    border-radius: 15px;
    margin-right: 15px;
    transition: background .3s ease;
    vertical-align: middle;
}

.popover,
.togglebutton label .toggle,
.popover,
.togglebutton .toggle {
    text-align: left;
}

.togglebutton label input[type=checkbox]:checked+.toggle:after,
.togglebutton input[type=checkbox]:checked+.toggle:after {
    left: 15px;
    background-color: rgb(33 109 236 / 90%);
}

.togglebutton label,
.togglebutton .input {
    cursor: pointer;
}

.togglebutton label .toggle:after,
.togglebutton .toggle:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #F1F1F1;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4);
    left: -5px;
    top: -2px;
    transition: left .3s ease, background .3s ease, box-shadow .1s ease;
}

//configuration 
.configuration {
    .card {
        border: none;
        .card-header {
            background-color: #f2f2f252;
        }
    }
    .accordion .card-header:after {
        font-family: 'FontAwesome';
        content: "\f107";
        float: right;
        font-size: 19px;
    }
    .accordion .card-header.collapsed:after {
        // symbol for "collapsed" panels 
        content: "\f106";
    }
    table th {
        border-top: none;
    }
}

//Userbalance
.processmovement.processed::after {
    content: "\f005";
    color: #0a0;
    font-weight: 800;
}

.processmovement::after {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #ddd;
}

.processmovement.notprocessed::after {
    content: "\f005";
    color: #bfb0b0;
}

.processmovement::after {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #ddd;
}

// General
.cursorpointer {
    cursor: pointer;
}

#map {
    width: 100%;
    height: 400px;
    background-color: #f0f0f0;
}

//admon schedule
.admon-schedule {
    p {
        margin: 10px 0 4px 0;
    }
}

.disabled {
    cursor: not-allowed;
}

.help-block {
    color: #ff1515;
    font-size: 14px;
}

.sortable_x {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    li {
        float: left;
    }
    li a {
        display: block;
        color: white;
        text-align: center;
        padding: 16px;
        text-decoration: none;
    }
    li a:hover {
        background-color: #111111;
    }
}

@media (max-width: 662px) {
    .sortable_x {
        margin: 0;
        padding: 0;
        display: block;
        li {
            float: none;
        }
    }
}